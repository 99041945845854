// Generated by ReScript, PLEASE EDIT WITH CARE


function applyPayload(t, entry) {
  var response = entry.response;
  if (response === undefined) {
    return ;
  }
  var $$final = entry.final;
  if ($$final !== undefined) {
    t.next(response);
    if ($$final) {
      t.complete();
      return ;
    } else {
      return ;
    }
  }
  
}

var RelayReplaySubject = {
  applyPayload: applyPayload
};

export {
  RelayReplaySubject ,
}
/* No side effect */
